import React from 'react';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  getPostCover,
  resolveId,
  SideBySideLayout,
} from '@wix/communities-blog-client-common';
import { CommentCountCompact } from '@app/external/comments/components/comment-count-compact';
import { ThreeDotsIcon } from '@app/external/common/components/icons/three-dots-icon';
import { LikeButtonWithCount } from '@app/external/common/components/like-button-with-count';
import Link from '@app/external/common/components/link/internal-link';
import { MoreButton } from '@app/external/common/components/more-button';
import { importPostActions } from '@app/external/common/components/post-actions';
import PostHeaderIcons from '@app/external/common/components/post-header-icons';
import PostListItemCategoryLabel from '@app/external/common/components/post-list-item-category-label';
import PostListItemCover from '@app/external/common/components/post-list-item-cover';
import PostListItemHeader from '@app/external/common/components/post-list-item-header';
import PostTitle from '@app/external/common/components/post-title';
import RatingsDisplay, {
  RatingsDisplayLayout,
} from '@app/external/common/components/ratings-display';
import { useSelector } from '@app/external/common/components/runtime-context';
import { HorizontalSeparatorForPostCard } from '@app/external/common/components/separator';
import ViewCountCompact from '@app/external/common/components/view-count-compact';
import { useFeedBorderWidth } from '@app/external/common/hooks/use-feed-border-width';
import { useFeedMetadataSettings } from '@app/external/common/hooks/use-feed-metadata-settings';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import useIsFeedDesignEnabled from '@app/external/common/hooks/use-is-feed-design-enabled';
import useLayoutColorClasses from '@app/external/common/hooks/use-layout-color-classes';
import usePermissions from '@app/external/common/hooks/use-permissions';
import usePostFontSize from '@app/external/common/hooks/use-post-font-size';
import {
  getIsMoreButtonEnabled,
  getMobileContentAlignment,
  getSBSLayoutImagePosition,
} from '@app/external/common/selectors/app-settings-selectors';
import { getSection } from '@app/external/common/selectors/section-selectors';
import { getContentAlignmentStyles } from '@app/external/common/services/content-alignment-helpers';
import {
  getFeedColorClassName,
  getLineCounts,
} from '@app/external/common/services/layout-config';
import { getPostActions } from '@app/external/common/services/post-actions';
import { getIsMemberAreaInstalled } from '@app/external/common/store/communities-context/communities-context-selectors';
import {
  getCommentCount,
  getViewCount,
} from '@app/external/common/store/post-counters/post-counters-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '@app/external/common/store/post-ratings/post-ratings-selector';
import alignmentStyles from '@app/external/common/styles/post-list-item-alignment.scss';
import postListItemStyles from '@app/external/common/styles/post-list-item.scss';
import { type LayoutConfigItemComponentProps } from '../../post-list/layout-config-component-props';
import styles from './side-by-side.scss';

const PostListItemSideBySide: React.FC<LayoutConfigItemComponentProps> = ({
  itemConfig,
  post,
  type,
}) => {
  const { isRTL } = useEnvironment();
  const { iconColorClassName } = useLayoutColorClasses();
  const { can, canSee } = usePermissions();
  const {
    showViewCount,
    showCommentCount,
    showLikeCount,
    showAuthorName,
    showPublishDate,
    showReadingTime,
    showMoreOptionsMenu,
    showCategoryLabel,
    showPostRating,
  } = useFeedMetadataSettings({ post });
  const { titleFontClassName, contentFontClassName } = useFontClassName();
  const postId = resolveId(post)!;

  const {
    isMoreButtonEnabled,
    alignment,
    postAverageRating,
    postTotalRatings,
    section,
    sideBySideLayoutImagePosition,
    totalComments,
    viewCount,
  } = useSelector((state) => {
    const postActions = getPostActions({
      post,
      canSee,
      enableShare: true,
      enableSubscribe: getIsMemberAreaInstalled(state),
    });
    const showMoreButton = Boolean(postActions.length);

    const contentAlignmentMobile = getMobileContentAlignment(state, isRTL);
    const defaultSection = getSection(state)!;

    return {
      isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
      section: defaultSection,
      sideBySideLayoutImagePosition: getSBSLayoutImagePosition(
        state,
        defaultSection,
      ),
      viewCount: getViewCount(state, postId),
      totalComments: getCommentCount(state, postId),
      alignment: getContentAlignmentStyles(contentAlignmentMobile, isRTL),
      postAverageRating: getPostAverageRating(state, postId),
      postTotalRatings: getPostTotalRatings(state, postId),
    };
  });
  const { borderWidth } = useFeedBorderWidth({ section });
  const { getPostClassName } = useIsFeedDesignEnabled(section);
  const { postTitleFontSize, postMetadataFontSize } = usePostFontSize(section);

  const coverImage = getPostCover(post);
  const lineCounts = getLineCounts(itemConfig, coverImage?.shouldRender);
  const postUrl = `/${post.slug}`;

  const containerClassName = classNames(
    styles.container,
    alignmentStyles[alignment],
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName(
      'border-color',
      'post-container',
      getFeedColorClassName(type, 'background-color'),
    ),
  );

  const contentWrapperClassName = classNames(
    styles.flexContainer,
    sideBySideLayoutImagePosition === SideBySideLayout.ImageLeft &&
      styles.pullImageLeft,
    isRTL && alignmentStyles.isRTL,
  );

  const linkClassName = classNames(
    styles.textWrapper,
    post.isPinned && styles.withIcons,
  );
  const headerClassName = classNames(
    styles.header,
    contentFontClassName,
    alignmentStyles.headerContainer,
  );

  const titleStyle = { fontSize: postTitleFontSize };
  const showCommentStats =
    showCommentCount && (totalComments > 0 || !post.isCommentsDisabled);
  const moreButtonId = `more-button-${resolveId(post)}`;

  const footerClassName = classNames(
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
    ),
    styles.footer,
  );

  const countersVisible = showLikeCount || showCommentStats || showViewCount;
  const isImagePositionNone =
    sideBySideLayoutImagePosition === SideBySideLayout.ImageNone;
  const showFooter =
    isMoreButtonEnabled || countersVisible || !isImagePositionNone;
  const showMoreButtonInHeading =
    isMoreButtonEnabled && !countersVisible && isImagePositionNone;

  const showPostCover = coverImage?.shouldRender && !isImagePositionNone;

  const coverWrapperClassName = classNames(
    styles.imageContainer,
    sideBySideLayoutImagePosition === SideBySideLayout.ImageLeft &&
      styles.rightMargin,
  );

  const moreButtonIcon = (
    <ThreeDotsIcon
      className={classNames(
        iconColorClassName,
        'blog-hover-container-element-fill',
      )}
    />
  );

  const isMoreButtonInHeadingEnabled =
    showMoreOptionsMenu &&
    showMoreButtonInHeading &&
    !showFooter &&
    isMoreButtonEnabled;
  const showHeader =
    isMoreButtonInHeadingEnabled ||
    showAuthorName ||
    showReadingTime ||
    showPublishDate;
  const separatorClass = showFooter
    ? styles.separator
    : classNames(styles.separator, styles.withoutFooter);

  return (
    <article
      className={containerClassName}
      tabIndex={0}
      style={{ borderWidth }}
      data-hook="post-list-item"
    >
      <div className={contentWrapperClassName}>
        <div
          className={classNames(
            styles.titleContainer,
            alignmentStyles.textAlign,
          )}
        >
          <Link to={postUrl} className={linkClassName} addHoverClasses={false}>
            <PostTitle
              type={type}
              title={post.title}
              lineCount={lineCounts.title}
              style={titleStyle}
              className={classNames(
                styles.title,
                titleFontClassName,
                showCategoryLabel && styles.withCategoryLabel,
              )}
            />
          </Link>
          {showCategoryLabel && (
            <PostListItemCategoryLabel
              className={alignmentStyles.categoryLabel}
              post={post}
              postListLayout={type}
            />
          )}
          {showPostRating && postTotalRatings ? (
            <RatingsDisplay
              className={classNames(
                postListItemStyles.rating,
                postListItemStyles[section],
                styles.rating,
                styles[type],
                alignmentStyles.rating,
              )}
              rating={postAverageRating}
              count={postTotalRatings}
              layout={RatingsDisplayLayout.with_range}
            />
          ) : null}
          {showHeader && (
            <PostListItemHeader
              className={headerClassName}
              post={post}
              showHeaderIcons={false}
              showMoreButton={isMoreButtonInHeadingEnabled}
              showProfileImage={false}
              style={{ fontSize: postMetadataFontSize }}
              moreButtonIcon={moreButtonIcon}
              moreButtonClass={styles.moreButtonInHeading}
              type={type}
            />
          )}
        </div>
        {showPostCover && (
          <div className={coverWrapperClassName}>
            <PostListItemCover
              post={post}
              postLink={postUrl}
              type={type}
              canPlayVideo
              isPublic={can('share', 'post', post)}
              videoClassName={styles.videoEmbed}
              imageClassName={styles.image}
              isMobile
            />
          </div>
        )}
      </div>
      {showFooter && (
        <div className={footerClassName}>
          {countersVisible && (
            <div
              className={styles.socialButtons}
              style={{ fontSize: postMetadataFontSize }}
            >
              {showLikeCount && (
                <div className={styles.socialButton}>
                  <LikeButtonWithCount
                    className="post-footer__like-button"
                    showZero
                    switchCounterAndButton
                    postId={postId}
                  />
                </div>
              )}
              {showViewCount && (
                <div
                  className={classNames(
                    'description-font',
                    styles.socialButton,
                  )}
                >
                  <ViewCountCompact count={viewCount} />
                </div>
              )}
              {showCommentStats && (
                <div
                  className={classNames(
                    'description-font',
                    styles.socialButton,
                  )}
                >
                  <CommentCountCompact count={totalComments} showZero />
                </div>
              )}
            </div>
          )}
          <PostHeaderIcons
            post={post}
            className={styles.icons}
            iconClassName="blog-button-fill"
          />
          {isMoreButtonEnabled && (
            <div className="blog-more-icon-fill">
              <MoreButton
                className={styles.moreButton}
                id={moreButtonId}
                icon={moreButtonIcon}
              >
                {async () => {
                  const PostActions = await importPostActions();
                  return <PostActions post={post} />;
                }}
              </MoreButton>
            </div>
          )}
        </div>
      )}
      <HorizontalSeparatorForPostCard className={separatorClass} />
    </article>
  );
};

export default PostListItemSideBySide;
