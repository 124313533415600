import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  getTPASettignsLiveSiteEditingDeprecated,
  resolveId,
} from '@wix/communities-blog-client-common';

import { useSelector } from '../../../common/components/runtime-context';
import { getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import CreatePostButton from '../create-post-button';
import { type LayoutConfigListComponentProps } from './layout-config-component-props';
import Masonry from './masonry-component';
import styles from './post-list.scss';

const PostListMasonry: React.FC<LayoutConfigListComponentProps> = ({
  category,
  posts,
  type,
  showCreatePostAction,
  ItemComponent,
  itemConfig,
}) => {
  const isRTL = useSelector(getIsRTL);
  const isLiveSiteEditingDeprecated = useSelector(
    getTPASettignsLiveSiteEditingDeprecated,
  );

  const masonryOptions = useMemo(
    () =>
      ({
        transitionDuration: 0,
        fitWidth: false,
        gutter: 32,
        originLeft: !isRTL,
      } as const),
    [isRTL],
  );

  return (
    <Masonry
      className={classNames(styles.masonry, styles[type])}
      options={masonryOptions}
      data-hook="post-list"
    >
      {showCreatePostAction && !isLiveSiteEditingDeprecated && (
        <div className={classNames(styles.listItem)} key="create-post">
          <CreatePostButton categorySlug={category && category.slug} />
        </div>
      )}
      {posts.map((post) => (
        <div
          className={classNames(styles.listItem, 'post-list__post-list-item')}
          key={resolveId(post)}
          id={resolveId(post)}
        >
          <ItemComponent type={type} post={post} itemConfig={itemConfig} />
        </div>
      ))}
    </Masonry>
  );
};

export default PostListMasonry;
