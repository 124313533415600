import React from 'react';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common';
import { type NormalizedPost } from '../../types';
import DotDotDot from '../dotdotdot';
import SimpleRenderer from '../simple-renderer';
import styles from './responsive-content-excerpt.scss';

type Props = {
  post: NormalizedPost;
};

const ResponsiveContentExcerpt: React.FC<Props> = ({ post }) => {
  return (
    <div>
      <DotDotDot
        clamp="auto"
        useExactLineHeight={true}
        className={styles.container}
      >
        <SimpleRenderer post={post} maxLength={POST_EXCERPT_MAX_LENGTH} />
      </DotDotDot>
    </div>
  );
};

export default ResponsiveContentExcerpt;
