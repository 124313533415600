import React from 'react';
import classNames from 'classnames';
import {
  getTPASettignsLiveSiteEditingDeprecated,
  resolveId,
} from '@wix/communities-blog-client-common';
import { type NormalizedPost } from '@app/external/common/types';
import { useSelector } from '../../../common/components/runtime-context';
import { isSeo as getIsSeo } from '../../../common/store/basic-params/basic-params-selectors';
import CreatePostButtonMobile from '../create-post-button-mobile';
import { type LayoutConfigListComponentProps } from './layout-config-component-props';
import styles from './post-list.scss';

const PostListMobile: React.FC<LayoutConfigListComponentProps> = (props) => {
  const isSeo = useSelector(getIsSeo);
  const isLiveSiteEditorDeprecated = useSelector(
    getTPASettignsLiveSiteEditingDeprecated,
  );

  return (
    <div data-hook="post-list">
      {props.showCreatePostAction && !isLiveSiteEditorDeprecated && !isSeo && (
        <div key="create-post">
          <CreatePostButtonMobile />
        </div>
      )}
      {props.posts.map((post, index) => {
        return (
          <PostListMobileItem
            key={post.id}
            post={post}
            index={index}
            ItemComponent={props.ItemComponent}
            itemConfig={props.itemConfig}
            type={props.type}
          />
        );
      })}
    </div>
  );
};

type ItemProps = {
  post: NormalizedPost;
  index: number;
} & Pick<
  LayoutConfigListComponentProps,
  'ItemComponent' | 'itemConfig' | 'type'
>;

const PostListMobileItem: React.FC<ItemProps> = ({
  post,
  type,
  ItemComponent,
  itemConfig,
  index,
}) => {
  const wrapperClass = classNames(
    styles.listItemMobile,
    'post-list__post-list-item',
  );
  const postId = resolveId(post);

  return (
    <div className={wrapperClass} key={postId} id={postId} data-id={postId}>
      <ItemComponent
        index={index}
        type={type}
        post={post}
        itemConfig={itemConfig}
      />
    </div>
  );
};

export default PostListMobile;
